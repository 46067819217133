import React from 'react'
import '../static/leaders.css'
import { useTranslation } from 'react-i18next'
import Layout from '../components/Layout'

export default function Leaders() {
    const { t } = useTranslation('leaders')

    return (
        <Layout>
            <div className="leaders">
                <div className="leaders__container container">
                    <h3 className="leaders__title">
                        { t('head') }
                    </h3>
                    <ul className="leaders__list">
                        <li className="leaders__item">
                            <div className="leaders__item-wrapper">
                                <div className="leaders__item-img director" />
                                <div className="leaders__item-text">
                                    <p className="leaders__item-job">
                                        { t('director_post') }
                                    </p>
                                    <p className="leaders__item-name">
                                        { t('director_name') }
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li className="leaders__item">
                            <div className="leaders__item-wrapper">
                                <div className="leaders__item-img woman" />
                                <div className="leaders__item-text">
                                    <p className="leaders__item-job">
                                        { t('accountat_post') }
                                    </p>
                                    <p className="leaders__item-name">
                                        { t('accountat_name') }
                                    </p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </Layout>
    )
}
