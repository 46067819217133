import React, { useContext, useEffect } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { isEmpty } from 'lodash'
import './i18n'
import NotFound from './pages/NotFound'
import Home from './pages/Home'
import Blog from './pages/Blog'
import BlogDetail from './pages/BlogDetail'
import LoginMobile from './components/MobilLogin'
import { REST } from './utils/urls'
import { usePostRequest } from './hooks/request'
import Vacancies from './pages/Vacancies'
import Vzr from './pages/Vzr'
import Travel from './pages/Travel'
import TravelNew from './pages/TravelNew'
import AutoNew from './pages/AutoNew'
import Boxes from './pages/Boxes'
import BoxesUr from './pages/BoxesUr'
import Offers from './pages/Offers'
import Box from './pages/Box'
import BoxUr from './pages/BoxUr'
import Partners from './pages/Partners'
import Docs from './pages/Docs'
import Partner from './pages/Partner'
import VacancyDetail from './pages/VacancyDetail'
import Rules from './pages/Rules'
import Politics from './pages/Politics'
import BoxOrder from './pages/BoxOrder'
import { AuthContext } from './contexts/AuthContext'
import Losses from './pages/Losses'
import Offices from './pages/Offices'
import BoxesUrSlug from './pages/BoxesUrSlug'
import SuccessPage from './pages/SuccessPage'
import ErrorPage from './pages/ErrorPage'
import AutoOrder from './pages/AutoOrder'
import Auto from './pages/Auto'
import AutoHome from './components/AutoHome'
import AutoDetail from './pages/AutoDetail'
import Ponimayu from './pages/Ponimayu'
import Leaders from './pages/Leaders'

export default function App() {
    const {
        // setRefreshToken, setAccessToken, setCmsToken, cmsToken, accessToken, refreshToken,
        setRefreshToken, setAccessToken, accessToken, refreshToken,
    } = useContext(AuthContext)
    // const CMSToken = usePostRequest({ url: CMS.AUTH, headers: {} })
    const auth = usePostRequest({ url: REST.LOGIN.AUTH, headers: {} })
    const refresh = usePostRequest({ url: REST.LOGIN.AUTH_REFRESH, headers: {} })

    // async function getCMSToken() {
    //     const data = {
    //         email: process.env.REACT_APP_CMS_LOGIN,
    //         password: process.env.REACT_APP_CMS_PASSWORD,
    //         passwordConfirmation: process.env.REACT_APP_CMS_PASSWORD,
    //     }
    //
    //     try {
    //         const { response, success } = await CMSToken.request({ data })
    //
    //         if (success) {
    //             localStorage.setItem('cmsToken', response.token)
    //             console.log(response.token)
    //             setCmsToken(response.token)
    //         }
    //     } catch (response) {
    //         console.log(response)
    //     }
    // }

    async function getToken() {
        const data = {
            username: process.env.REACT_APP_BACKEND_LOGIN || '79104054200',
            password: process.env.REACT_APP_BACKEND_PASSWORD || 'humo_service_123',
        }

        try {
            const { success, response } = await auth.request({ data })

            setRefreshToken(response.refresh)
            setAccessToken(response.access)
            if (success) localStorage.setItem('token', JSON.stringify(response))
        } catch (response) {
            console.log(response)
        }
    }

    const token = JSON.parse(localStorage.getItem('token'))

    async function onRefreshToken() {
        const { success, response } = await refresh.request({ data: { refresh: refreshToken } })

        if (success) {
            const data = { access: response.access, changePassword: token.changePassword, refresh: response.refresh }
            setAccessToken(response.access)
            localStorage.setItem('token', JSON.stringify(data))
        }
    }

    useEffect(() => {
        // if (isEmpty(cmsToken)) getCMSToken()

        if (isEmpty(accessToken)) getToken()
    }, [])

    useEffect(() => {
        if (refreshToken) setInterval(onRefreshToken, 270000)
    }, [refreshToken])

    return (
        <BrowserRouter>
            <Switch>
                <Route path="/" component={Home} exact />
                <Route path="/jur" component={BoxesUr} exact />
                <Route path="/blog" component={Blog} exact />
                <Route path="/blog/:slug" component={BlogDetail} exact />
                <Route path="/vacancies" component={Vacancies} exact />
                <Route path="/vacancy/:slug" component={VacancyDetail} exact />
                <Route path="/partners" component={Partners} exact />
                <Route path="/docs" component={Docs} exact />
                <Route path="/partners/:id" component={Partner} exact />
                <Route path="/mobile-login" component={LoginMobile} exact />
                <Route path="/travel" component={Travel} exact />
                <Route path="/travelnew" component={TravelNew} exact />
                <Route path="/autonew" component={AutoNew} exact />
                <Route path="/jur/product/:slug" component={BoxUr} exact />
                <Route path="/jur/product" component={BoxesUr} exact />
                <Route path="/jur/products/:slug" component={BoxesUrSlug} exact />
                <Route path="/boxes/order/:slug" component={BoxOrder} exact />
                <Route path="/boxes/:slug" component={Box} exact />
                <Route path="/boxes" component={Boxes} exact />
                <Route path="/travel-order" component={Vzr} exact />
                <Route path="/politics" component={Politics} exact />
                <Route path="/rules" component={Rules} exact />
                <Route path="/rules" component={Rules} exact />
                <Route path="/losses" component={Losses} exact />
                <Route path="/offices" component={Offices} exact />
                <Route path="/success" component={SuccessPage} exact />
                <Route path="/ponimayu" component={Ponimayu} exact />
                <Route path="/error" component={ErrorPage} exact />
                <Route path="/offers" component={Offers} exact />
                <Route path="/auto/order/:product" component={AutoOrder} exact />
                <Route path="/auto/:slug" component={AutoDetail} exact />
                <Route path="/auto" component={Auto} exact />
                <Route path="/autohome" component={AutoHome} exact />
                <Route path="/leaders" component={Leaders} exact />
                <Route path="" component={NotFound} exact />
            </Switch>
        </BrowserRouter>
    )
}
