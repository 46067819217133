import box from './box'
import blog from './blog'
import home from './home'
import common from './common'
import losses from './losses'
import offices from './offices'
import partners from './partners'
import travel from './travel'
import subs from './subs'
import vacancy from './vacancy'
import auto from './auto'
import leaders from './leaders'

export default {
    box,
    blog,
    home,
    common,
    losses,
    offices,
    partners,
    travel,
    subs,
    vacancy,
    auto,
    leaders,
}
